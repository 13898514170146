import { Link } from "react-router-dom";
import styles from "../../css/minhaconta.module.css";
import jobIcon from "../../imgs/jobIcon.png";
import handshakeIcon from "../../imgs/handshake.png";

const JobCard = (props) => {
  console.log(props.value);
  const job = props.value.job;
  const user = props.value.user;
  return (
    <div className={styles.cardJob}>
      <Link style={{ textDecoration: "none" }} to={`/meustrabalhos/${job.id}`}>
        <>
          <div className={styles.headerDateTitle}>
            <h1>{job.headline}</h1>
          </div>
          {/* <p style={{fontFamily: "Avenir Next", color: "#333", fontSize: 12, marginTop: -15, fontWeight: "600"}}>Postado a {job.createdAt.toDate().toLocaleDateString('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' })} </p> */}

          <div className={styles.headerJob}>
            <img src={jobIcon} width={20} height={20} alt="MEUJOB" />
            <p>{job.tradeSelected}</p>
          </div>

          <p
            style={{
              fontSize: 13,
              color: "#8c8c8c",
              fontFamily: "DM Sans",
              fontWeight: 500,
              marginTop: -5,
            }}
          >
            {job.createdAt.toDate().toLocaleDateString("pt-BR", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </p>
        </>
      </Link>

      {job.userId == user.uid && (
        <>
          {job.userHired == "" ? (
            <>
              <div>
                <p style={{ color: "#8c8c8c", fontFamily: "Raleway" }}>
                  Comerciantes locais adequados foram alertados sobre o seu
                  trabalho. Assim que houver interesse, informaremos.
                </p>
              </div>
              <div className={styles.inviteWorkersContainer}>
                <h3>Tem mais trabalhadores, rápido!</h3>

                <div
                  className={styles.headerJob}
                  style={{
                    borderTop: "0.5px solid #eee",
                    borderBottom: "0.5px solid #eee",
                    marginTop: -20,
                  }}
                >
                  <img
                    src={handshakeIcon}
                    width={35}
                    height={25}
                    alt="MEUJOB"
                    style={{ borderRadius: "100%" }}
                  />
                  <p>10 convites restantes</p>
                </div>
                <div className={styles.inviteBtnContainer}>
                  <Link className={styles.inviteBtn}>
                    Convida Trabalhadores
                  </Link>{" "}
                </div>
              </div>
            </>
          ) : (
            <>
              {job.feedback == false ? (
                <div className={styles.inviteWorkersContainer}>
                  <h3>Trabalhador contratado!</h3>

                  <div
                    className={styles.headerJob}
                    style={{
                      borderTop: "0.5px solid #eee",
                      borderBottom: "0.5px solid #eee",
                      marginTop: -20,
                    }}
                  >
                    <img
                      src={handshakeIcon}
                      width={35}
                      height={25}
                      alt="MEUJOB"
                      style={{ borderRadius: "100%" }}
                    />
                    <p>Deixa a tua crítica após ele completar o seu serviço</p>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    border: "2px solid #e8e8e9",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <p style={{ fontFamily: "Raleway", color: "#8c8c8c" }}>
                    Crítica submetida
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
      <hr style={{ border: "3px solid #fff", marginTop: 25 }}></hr>
    </div>
  );
};

export default JobCard;
