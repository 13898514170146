import { differenceInMonths } from "date-fns";
import styles from "../../css/inviteWorkers.module.css";
import {
  doc,
  updateDoc,
  arrayUnion,
  getDocs,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db } from "../../firebase";
import React, { useState } from "react";
import jobIcon from "../../imgs/jobIcon.png";
import local from "../../imgs/locationIcon.png";
import user from "../../imgs/userIcon.png";
import addIcon from "../../imgs/addIcon.png";

const WorkerCard = ({ worker, job }) => {
  console.log(worker);

  const [invited, setInvited] = useState(
    job && worker && worker.invitedJobs.includes(job.id)
  );
  const createdAt = worker.trades_member_since.toDate();
  const monthsSinceCreation = differenceInMonths(new Date(), createdAt);

  let formattedString = "";
  if (monthsSinceCreation === 0) {
    formattedString = "Membro este mês";
  } else {
    formattedString = `Membro há ${monthsSinceCreation} meses`;
  }

  const inviteWorker = async (workerId) => {
    try {
      if (job.invitesLeft < 1) {
        alert("Esgotaste o teu número de convites");
        return;
      }
      const userDocRef = doc(db, "users", workerId);
      const jobDocRef = doc(db, "jobs", job.id);

      await updateDoc(userDocRef, {
        invitedJobs: arrayUnion(job.id),
      });

      await updateDoc(jobDocRef, {
        invitedUsers: arrayUnion(workerId),
        invitesLeft: increment(-1),
      });

      console.log(
        `Job ${job.id} has been added to the invitedJobs array for user ${workerId}`
      );

      // Update the local state to reflect that the worker has been invited
      setInvited(true);
    } catch (error) {
      console.error("Error inviting worker:", error);
    }
  };

  const removeInviteWorker = async (workerId) => {
    try {
      const userDocRef = doc(db, "users", workerId);
      const jobDocRef = doc(db, "jobs", job.id);

      await updateDoc(userDocRef, {
        invitedJobs: arrayRemove(job.id),
      });

      await updateDoc(jobDocRef, {
        invitedUsers: arrayUnion(workerId),
        invitesLeft: increment(1),
      });

      console.log(
        `Job ${job.id} has been removed from the invitedJobs array for user ${workerId}`
      );

      // Update the local state to reflect that the worker is no longer invited
      setInvited(false);
    } catch (error) {
      console.error("Error removing invitation:", error);
    }
  };

  return (
    <div>
      <div className={styles.categoriaConvitesDuplos}>
        <div className={styles.tituloCriticas}>
          <header>
            <h2 className={styles.title}>{worker.workName}</h2>
            {invited ? (
              <button onClick={() => removeInviteWorker(worker.id)}>
                Remover Convite
              </button>
            ) : (
              <button onClick={() => inviteWorker(worker.id)}>
                <img
                  style={{ marginRight: 10, filter: "invert(1)" }}
                  src={addIcon}
                  width={15}
                  height={15}
                  alt="MeuJob"
                />
                Convide para orçamentar
              </button>
            )}
          </header>
          <h5>
            {worker.reviewCount == 0 ? (
              "Ainda sem críticas"
            ) : (
              <>
                {worker.reviewCount} crítica(s) -{" "}
                {(worker.positiveReviewCount / worker.reviewCount) * 100} %
                positivo
              </>
            )}
          </h5>
        </div>

        <div className={styles.local}>
          <div className={styles.iconAndTitle}>
            <img src={jobIcon} width={20} height={20} />
            <b>{worker.tradesSelected[0]}</b>
          </div>
          <div className={styles.iconAndTitle}>
            <img src={local} width={20} height={20} />
            <p style={{ marginTop: 0 }}>{worker.distritos[0]}</p>
          </div>
          <div style={{ marginTop: -7.5 }} className={styles.iconAndTitle}>
            <img src={user} width={20} height={20} />
            <p style={{ marginTop: 0 }}>{formattedString}</p>
          </div>
        </div>

        <p
          style={{
            borderRadius: 0,
            paddingBottom: 10,
            top: 4,
            alignItems: "center",
            borderBottom: "1px solid #508ce4",
            fontFamily: "Raleway",
          }}
        >
          {worker.description}
        </p>
      </div>
    </div>
  );
};

export default WorkerCard;
