import React from "react";
import { Link } from "react-router-dom";
import workers from ".././imgs/postsHeader.png";
import styles from "../css/home.module.css";

const TradesPage = () => {
  return (
    <div>
      <div className={styles.divCabecalho}>
        <div>
          <h1 className={styles.title}>
            A melhor forma<br></br> <b>de encontrar</b>
            <br></br> trabalhadores.
          </h1>
          <h3 className={styles.subtitle}>
            Ajudamos proprietários a encontrar o trabalhador ideal, e bons
            trabalhadores a ter sucesso.
          </h3>

          <Link
            to="/aplicativo-de-comerciante"
            style={{ textDecoration: "none" }}
          >
            <li className={styles.btnApply}>
              <h3>Registar agora</h3>
            </li>
          </Link>
        </div>
        <div className={styles.workers_side}>
          <img className={styles.imgWorkers} src={workers} />
        </div>
      </div>
      <div className={styles.zonaComecar} style={{ marginBottom: -150 }}>
        {" "}
        <h1 className={styles.titles}>
          Pagamento simples<br></br>
          <b>durante o uso</b>
        </h1>
        <div className={styles.divAboutUs}>
          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>1</h1>
              <div>
                <h5>Escolhe os teus trabalhos</h5>
                <p>
                  Enviaremos leads apenas para onde você quiser. Escolhe em
                  quais leads está interessado.
                </p>
              </div>
            </header>
          </div>

          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>2</h1>
              <div>
                <h5>Lista Restrita</h5>
                <p>
                  Os proprietários fazem sua escolha com base nos perfis e
                  feedback dos negócios interessados.
                </p>
              </div>
            </header>
          </div>

          <div className={styles.divAboutUsFilho}>
            <header>
              <h1 className={styles.numberPasso}>3</h1>
              <div>
                <h5>Liga para negociar</h5>
                <p>
                  Depois de selecionados, cobraremos uma pequena taxa e
                  colocaremos você em contato com o proprietário.
                </p>
              </div>
            </header>
          </div>
        </div>
        <div className={styles.divAboutUsDesc}>
          <h1 style={{ width: "100%" }}>
            A maneira mais eficaz e econômica de conquistar trabalho.
          </h1>
          <p>
            No MeuJob, enviar uma mensagem de introdução a um proprietário é
            gratuito. Só paga uma taxa quando o proprietário o seleciona como
            finalista e os detalhes de contacto verificados são trocados. As
            nossas taxas de finalista geralmente variam entre 4 € e 8 €,
            dependendo do tamanho do trabalho. Não há taxas de adesão, taxas de
            associação ou custos ocultos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TradesPage;
