import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateDoc,
  doc,
  getDoc,
  collection,
  addDoc,
  increment,
} from "firebase/firestore";
import { db } from "../firebase";
import styles from "../css/leavereview.module.css";

const LeaveReview = () => {
  const { jobId, workerId } = useParams();

  const [worker, setWorker] = useState(null);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviewOption, setReviewOption] = useState("");
  const [reviewText, setReviewText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobAndWorkerData = async () => {
      // Fetch job data by jobId
      const jobDocRef = doc(db, "jobs", jobId);
      const jobSnapshot = await getDoc(jobDocRef);

      if (jobSnapshot.exists()) {
        const jobData = jobSnapshot.data();
        setJob(jobData);
      } else {
        console.log(`Job with ID ${jobId} not found.`);
      }

      // Fetch worker data by workerId
      const workerDocRef = doc(db, "users", workerId);
      const workerSnapshot = await getDoc(workerDocRef);

      if (workerSnapshot.exists()) {
        const workerData = workerSnapshot.data();
        setWorker(workerData);
      } else {
        console.log(`Worker with ID ${workerId} not found.`);
      }

      // Data fetching is complete, set loading to false
      setLoading(false);
    };

    fetchJobAndWorkerData();
  }, [jobId, workerId]);

  const sendReview = async () => {
    const jobDocRef = doc(db, "jobs", jobId);
    const userRef = doc(db, "users", workerId);

    try {
      await updateDoc(jobDocRef, {
        feedback: true,
      });

      await updateDoc(userRef, {
        reviewCount: increment(1),
      });

      if (reviewOption == "positive") {
        await updateDoc(userRef, {
          positiveReviewCount: increment(1),
        });
      }

      const reviewsCollectionRef = collection(userRef, "reviews");

      // Add a new document to the "reviews" subcollection
      await addDoc(reviewsCollectionRef, {
        reviewText,
        reviewOption,
      });
      navigate("/meustrabalhos?reviewSubmitted=true");
      console.log("Feedback updated successfully.");
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };

  if (loading) {
    return (
      <h2 style={{ fontFamily: "Avenir Next", textAlign: "center" }}>
        A carregar...
      </h2>
    );
  }

  const reviewCard = (text, imageUrl, option) => {
    return (
      <div className={styles.headerExperience}>
        <input
          type="checkbox"
          id={`reviewCheckbox-${option}`}
          name="reviewCheckbox"
          checked={reviewOption === option}
          onChange={() => setReviewOption(option)}
          className={styles.customCheckbox}
        />
        <div
          style={{
            marginTop: -15,
            fontWeight: "400",
            fontFamily: "DM Sans",
            letterSpacing: -0.5,
          }}
        >
          <p>{text}</p>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <h2>Deixar Crítica</h2>
      <h3 style={{ marginTop: -25 }}>
        {worker.firstName} {worker.lastName} de {worker.workName} "
        {job.headline}"
      </h3>
      <div className={styles.containerSub}>
        <h3>Como é que foi a tua experiência?</h3>
        <div className={styles.tabela}>
          {reviewCard("Positivo", "", "positive")}
          {reviewCard("Neutro", "", "neutral")}
          {reviewCard("Negativo", "", "negative")}
        </div>
        <div className={styles.textAreaContainer}>
          <textarea
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            rows={10}
            cols={80}
            placeholder="Escreve aqui a tua crítica..."
          />
        </div>
        <div>
          <p style={{ color: "#508ce4" }}>
            Este feedback ficará visível no perfil de {worker.firstName}{" "}
            {worker.lastName} para outros clientes verem. Não poderá editar após
            publicado. Por favor, certifique-se de que você está satisfeito com
            o seu feedback antes de continuar.
          </p>
        </div>
        <div className={styles.botoes}>
          <button onClick={() => sendReview()}>Enviar crítica</button>
          <button
            style={{ color: "#000", backgroundColor: "#eee" }}
            onClick={() =>
              navigate(`/meustrabalhos/${jobId}/trabalhador/${workerId}`)
            }
          >
            Eu não estou pronto para deixar uma crítica
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveReview;
