const InterestedUserCard = (props) => {
  return (
    <div>
      <div
        style={{
          display: "inline-flex",
          marginTop: -20,
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          boxShadow: "inset 0 0 30px rgba(0, 0, 0, 0.08)",
          border: "0.5px solid #bfbfbf",
          marginTop: 2.5,
          borderRadius: 100,
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: 16,
            textAlign: "left",
            color: "#333",
            borderRadius: 5,
            paddingLeft: 25,
            fontFamily: "DM Sans",
          }}
        >
          {props.value.firstName} {props.value.lastName}
        </p>
        <br></br>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: 14,
            padding: 10,
            paddingRight: 25,
            color: "#8c8c8c",
          }}
        >
          {props.value.reviewCount == 0 ? (
            "Ainda sem críticas"
          ) : (
            <>
              {props.value.reviewCount} crítica(s) -{" "}
              {(props.value.positiveReviewCount / props.value.reviewCount) *
                100}{" "}
              % positivo
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default InterestedUserCard;
